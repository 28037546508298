<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">Experiment 1 - Pre-Lab Exercise</h2>

      <p class="mb-n3">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pt-6 mr-2 pr-0" cols="6">
            a) What is the molecular geometry of <stemble-latex :content="TeFvariant" />
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="3">
            <v-select
              v-model="inputs.geomTeF"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-0">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pt-6 mr-2 pr-0" cols="6">
            b) What is the electron geometry of <stemble-latex content="$\,\ce{H2CO}$" />
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="3">
            <v-select
              v-model="inputs.geomH2CO"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-2">
        c) Write the AXE notation for
        <stemble-latex content="$\ce{H2O}.$" />
      </p>

      <chemical-notation-input
        v-model="inputs.AXEnotationH2O"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-n1">
        d) From
        <chemical-latex content="AX4E_0" />
        to
        <chemical-latex content="AX3E1," />
        what is the change in molecular geometry?
      </p>

      <p class="mb-2">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="4">
            <v-select
              v-model="inputs.AX4E0toAX3E1a"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>

          <v-col class="d-flex my-0 pb-0 pt-6 ml-6" cols="1"> to </v-col>

          <v-col class="d-flex my-0 pb-0 pl-0 ml-n3" cols="4">
            <v-select
              v-model="inputs.AX4E0toAX3E1b"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-n1">
        e) What are the formal charges of both chlorines and iodine in
        <chemical-latex content="ICl2^-?" />
      </p>

      <p class="mb-n3">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="3">
            <v-select
              v-model="inputs.formalChargesICl2I"
              :items="charges"
              item-text="text"
              item-value="value"
              label="Charge on I:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-2" cols="3">
            <v-select
              v-model="inputs.formalChargesICl2Cl"
              :items="charges"
              item-text="text"
              item-value="value"
              label="Charge on Cl:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'OleMiss116Lab1Prelab',
  components: {ChemicalNotationInput, ChemicalLatex, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        geomTeF: null,
        geomH2CO: null,
        AXEnotationH2O: null,
        AX4E0toAX3E1a: null,
        AX4E0toAX3E1b: null,
        formalChargesICl2I: null,
        formalChargesICl2Cl: null,
      },
      items: [
        {text: 'Bent', value: 'bent'},
        {text: 'Linear', value: 'linear'},
        {text: 'Octahedral', value: 'octahedral'},
        {text: 'See-saw', value: 'seesaw'},
        {text: 'Square planar', value: 'squarePlanar'},
        {text: 'Square pyramidal', value: 'squarePyramid'},
        {text: 'T-shaped', value: 'tshaped'},
        {text: 'Tetrahedral', value: 'tetrahedral'},
        {text: 'Trigonal bipyramidal', value: 'trigBipyramid'},
        {text: 'Trigonal planar', value: 'trigPlanar'},
        {text: 'Trigonal pyramidal', value: 'trigPyramid'},
      ],
      charges: [
        {text: '-4', value: '-4'},
        {text: '-3', value: '-3'},
        {text: '-2', value: '-2'},
        {text: '-1', value: '-1'},
        {text: 'Neutral', value: '0'},
        {text: '+1', value: '+1'},
        {text: '+2', value: '+2'},
        {text: '+3', value: '+3'},
        {text: '+4', value: '+4'},
      ],
    };
  },
  computed: {
    TeFinteger() {
      return this.taskState.getValueBySymbol('TeFinteger').content;
    },
    TeFvariant() {
      if (this.TeFinteger.value === 1) {
        return '$\\,\\ce{ TeF4}?$';
      } else if (this.TeFinteger.value === 2) {
        return '$\\,\\ce{ TeF6}?$';
      } else {
        return 'Error';
      }
    },
  },
};
</script>
<style scoped></style>
